import axios from "axios";

const config = {
    production: {
        apiUrl: 'https://springboot-x9zp-98727-4-1318602640.sh.run.tcloudbase.com/api/quiz',
        serviceUrl: 'https://flask-g5bl-98727-4-1318602640.sh.run.tcloudbase.com/api/quiz/draw_result',
    },
    development: {
        apiUrl: 'http://localhost:80/api/quiz',
        serviceUrl: 'http://localhost:80/api/quiz/draw_result',
    }
};

/*
api/quiz
api/quiz/thanks_code
api/quiz/is_repeat
 */
const fetchData = async (path, data, method) => {
    let response;
    try {
        if (method === 'GET') {
            response = await axios.get(`${config[process.env.NODE_ENV].apiUrl}/${path}`, { params: data });
        } else if (method === 'POST') {
            response = await axios.post(`${config[process.env.NODE_ENV].apiUrl}${path}`, data);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return response
};

const fetchDrawData = async (data) => {
    let response;
    try {
        response = await axios.post(`${config[process.env.NODE_ENV].serviceUrl}`, data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return response
};

export { fetchData, fetchDrawData };
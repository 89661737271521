import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useState } from 'react';

const CopyButton = ({ text }) => {
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
                setLoading(false);
            }, 2000);
        } catch (error) {
            console.error('Failed to copy text:', error);
            setLoading(false);
        }
    };

    return (
        <Button
            type="primary"
            icon={<CopyOutlined />}
            loading={loading}
            onClick={handleClick}
            className="restart-button"
        >
            {copied ? '已复制' : '点击复制'}
        </Button>
    );
};

export default CopyButton;

import React, {
    useRef,
    useState,
    useEffect
} from "react";
import { render } from "react-dom";

import gsap from "gsap";
import {Button, ConfigProvider, Slider} from 'antd';
import questionsData from "../documents/questions.json"
// import fetchData from "../config";
import MyChart from "../chart/chart";
import CopyButton from "../components/copyButton";
import {fetchData} from "../config";
const quiz_title = "日常行为调查"
const quiz_start = "开始"

const questions = questionsData
let graphData = []
function useCounter(initialState) {
    const [value, setValue] = useState(initialState);
    const reset = () => setValue(0);

    const add = () => setValue((value) => (value += 1));

    return { value, add, reset };
}

function Question({
                      data,
                      buttonText,
                      hasButton = true,
                      onQuestionButtonClick,
                  }) {
    const questionRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(
            questionRef.current.querySelector(".question-text"),
            {
                x: 40,
                opacity: 0
            },
            {
                x: 0,
                opacity: 1,
                duration: 0.4
            }
        );
    }, [data]);

    const [sliderValue, setSliderValue] = useState(0);
    const handleChange = value => {
        setSliderValue(value);
    };
    const handleButtonClick = () => {
        // 点击确认按钮时，将用户选择的值传递给父组件，并重置Slider的值
        onQuestionButtonClick(sliderValue, data);
        setSliderValue(0); // 重置Slider的值
    };
    const getColor = value => {
        if (value >= 0 && value <= 2) {
            return '#30cfd0';
        } else if (value >= 3 && value <= 5) {
            return '#269198';
        } else if (value >= 6 && value <= 8) {
            return '#1C6F77';
        } else {
            return '#de054e';
        }
    };
    return (
        <div className="question" ref={questionRef}>
            <div className="question-inner">
                <h2 className="question-text">{data.text}</h2>
                <Slider
                    min={0}
                    max={10}
                    defaultValue={-1}
                    value={sliderValue}
                    trackStyle={{ backgroundColor: getColor(sliderValue) }}
                    onChange={handleChange}
                />
            </div>
            {hasButton && (
                <Button
                    type="primary"
                    size="large"
                    style={{ marginTop: '20px' }}
                    onClick={handleButtonClick}
                >
                    {buttonText}
                </Button>
            )}
        </div>
    );
}

function Results({ code }) {
    return (
            <div className="result">
                <div className="result-item is-correct">
                    复制该奖励码到平台中<br/>{code}
                </div>
                <CopyButton text={code} />
            </div>);
}

function QuizApp() {
    const [startTime, setStartTime] = useState(null);
    const [gameStarted, setGameStarted] = useState(false);
    const totalQuestion = questions.length - 1;
    const gameRef = useRef(null);

    const question = useCounter(0);

    const [idNumber, setIdNumber] = useState(-1);
    const [rewardCode, setRewardCode] = useState("");
    const handleNewQuestionClick = async (selectedValue, currQuestion) => {
        if (totalQuestion >= question.value) {
            // console.log("selectedValue:" + selectedValue)
            questions[currQuestion.id].selection = selectedValue;
            graphData.push(selectedValue)
            question.add();
        }
        if (totalQuestion === question.value) {
            const resultString = JSON.stringify(questions.map(({text, selection}) => ({text, selection})), null, 2);
            const req_data = {
                "ipAddress": "",
                "recordUuid": "",
                "recordFinishedTime": Math.floor(Date.now() / 1000) - startTime,
                "optionsConfig": resultString
            }
            // console.log("fetching")
            await fetchData("", req_data, "POST").then(
                resp => {
                    setIdNumber(resp.data.data.id) // 设置插入记录返回的id，用来分组实验
                    setRewardCode(resp.data.data.uuid)
                }
            ).catch(err => {
                console.log(err)
            })
            // console.log("fetch done")
            // question.add();
        }
    };
    const startGame = () => {
        setStartTime(Math.floor(Date.now() / 1000)); // Start time in seconds
        setGameStarted(true);
    };

    const indicatorBg = (index) => {
        if (question.value > index) {
            return "#fff";
        } else if (question.value === index) {
            return "#29b5d5";
        } else {
            return "rgba(255,255,255,.2)";
        }
    };

    useEffect(() => {
        if (gameStarted) {
            document.body.classList.add("game-started");
        } else {
            document.body.classList.remove("game-started");
        }
    }, [gameStarted]);

    useEffect(() => {
        if (question.value > totalQuestion) {
            gameRef.current.scrollTop = 0;
        }
    }, [question.value, totalQuestion]);
    return (
        <div
            className="game"
            ref={gameRef}
            data-game-started={gameStarted ? true : null}
            data-game-finished={question.value > totalQuestion ? true : null}
        >
            <div className="intro">
                <div className="intro-inner">
                    <h1 className="intro-title">{quiz_title}</h1>
                    {!gameStarted && (
                        <>
                            <p className="intro-desc">
                                {`本场测验共 ${questions.length} 道问题`}
                            </p>
                            <p className="intro-desc">
                                {`时间限制：无`}
                            </p>

                            <button
                                className="intro-button"
                                onClick={() => startGame()}
                            >
                                {quiz_start}
                            </button>
                        </>
                    )}
                    {gameStarted && (
                        <div className="indicator">
                            {questions.map((q, index) => {
                                return (
                                    <span
                                        className="indicator-item"
                                        style={{
                                            backgroundColor: indicatorBg(index)
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {question.value >= totalQuestion && (
                        <>
                            <Results code={rewardCode}/>
                        </>
                    )}
                </div>
            </div>
            <div className="game-area">
                {questions[question.value] && (
                    <Question
                        data={questions[question.value]}
                        buttonText={
                            question.value !== totalQuestion ? "确认" : "结束"
                        }
                        onQuestionButtonClick={handleNewQuestionClick}
                    />
                )}
                {!questions[question.value] && idNumber !== -1 &&(
                    <MyChart data={graphData} idNumber={idNumber}/>
                )}
            </div>
        </div>
    );
}

render(<ConfigProvider
        theme={{
            components: {
                Slider: {
                    dotSize: 30,
                    handleSize: 30,
                    controlSize: 20,
                    railSize: 10
                },
            },
        }}
    >
    <QuizApp />
    </ConfigProvider>, document.querySelector("#app"));

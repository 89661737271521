import React, {useEffect, useState} from 'react';
import ReactECharts from 'echarts-for-react';
import {fetchDrawData} from "../config";
import {Skeleton} from "antd";

const MyChart = ({ data, idNumber }) => {
    const [notReady, setNotReady] = useState(true);
    const [x_arrays, setX_arrays] = useState([]);
    const [y_arrays, setY_arrays] = useState([]);
    const [totalScore, setTotalScore] = useState(0);

    useEffect(() => {
        setTotalScore(data.reduce((total, num) => total + num, 0))
        // console.log("totalScore:" + totalScore)
        // console.log("idNumber:" + idNumber)
        fetchDrawData({ idNumber: idNumber, totalScore: totalScore })
            .then(resp => {
                setX_arrays(resp.data.data.x_axis.map(value => parseFloat((parseFloat(value).toFixed(2) * 100).toFixed(2))))
                setY_arrays(resp.data.data.y_axis)
                setNotReady(false)
            }).catch(err => {
            console.log(err)
        })
    }, [data, idNumber, totalScore]);

    // Configure the chart options
    const option = {
        title: {
            text: '日常调查结果分布', // 添加图表标题
            textStyle: {
                color: '#1f8197', // 设置标题颜色
                fontSize: 16 // 设置标题字体大小
            },
            left: 'center' // 标题居中显示
        },
        xAxis: {
            name: '分数区间',
            type: 'category',
            data: x_arrays, // Sample x-axis labels
            axisLine: {
                show: true, // Show x-axis line
                lineStyle: {
                    color: '#333' // Customize the x-axis line color
                }
            },
            axisTick: {
                show: false // Hide x-axis ticks
            },
            axisLabel: {
                interval: 0, // 控制标签的显示间隔
                show: true,
                formatter: function (value, index) {
                    if (index === 0) {
                        return 0; // 如果是 0 或 最后一个值，则显示 value
                    } else if (index === x_arrays.length - 1) {
                        return 100;
                    } else {
                        return ''; // 否则不显示标签

                    }
                }
            }
        },
        yAxis: {
            show: false // Hide y-axis
        },
        tooltip: {
            show: true, // Show tooltip
            trigger: 'axis', // Set trigger type to show tooltip
            axisPointer: {
                type: 'line', // Set axis pointer type to line
                label: {
                    show: true, // Show label
                    formatter: '{value}', // Format the label value
                    precision: 2 // Set precision of label value
                }
            }
        },
        series: [{
            name: '人群占比(%)',
            type: 'line',
            data: y_arrays,
            markLine: {
                symbol: 'none',
                lineStyle: {
                    color: '#FF0000',
                    type: 'solid'
                },
                data: [{
                    xAxis: totalScore * 10
                }],
                label: {
                    show: true,
                    position: 'end',
                    formatter: '你在这里！',
                    color: '#333'
                }
            }
        }]
    };

    return (
        <Skeleton loading={notReady}>
            <ReactECharts
                option={option}
                style={{ height: '400px', width: '100%' }}
            />
        </Skeleton>
    );
};

export default MyChart;
